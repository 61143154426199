import { useEffect } from 'react';

import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import GlobalError from '@components/Error/GlobalError';
import NotFound from '@components/Error/NotFound';
import { initClientAction } from '@containers/app/slice';
import Login from '@containers/auth/login';
import ExanView from '@containers/exams';
import FlowDetails from '@containers/flowDetails';
import Flows from '@containers/flowList';
import Home from '@containers/home';
import Layout from '@containers/layout';
import Projects from '@containers/projects';
import Sessions from '@containers/sessions';
import Vouchers from '@containers/vouchers';
import WorkFlows from '@containers/workflows';
import growthBook from '@utils/growthbook';
import { useAppDispatch, useAppSelector } from '@utils/hooks';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Login />} errorElement={<GlobalError />}>
        <Route element={<Layout />}>
          <Route path="projects" element={<Projects />}>
            <Route path=":project_uuid" element={<Projects />} />
          </Route>
          <Route path="home">
            <Route index element={<Home />} />
          </Route>
          <Route path="flows">
            <Route index element={<Flows />} />
            <Route path=":flowId" element={<FlowDetails />} />
          </Route>
          <Route path="sessions">
            <Route index element={<Sessions />} />
          </Route>
          <Route path="vouchers">
            <Route index element={<Vouchers />} />
          </Route>
          <Route path="workFlows">
            <Route index element={<WorkFlows />} />
          </Route>
          <Route path="exams">
            <Route index element={<ExanView />} />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </>,
  ),
);

const App = () => {
  console.log('App Version:', process.env.VERSION);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(initClientAction());
  }, [initClientAction, dispatch]);

  const { loading } = useAppSelector((state) => state.app);

  useEffect(() => {
    growthBook.loadFeatures({ autoRefresh: true });
    growthBook.setAttributes({
      url: window.location.href,
      browser: navigator.userAgent,
    });
  }, []);

  return (
    <GrowthBookProvider growthbook={growthBook}>{!loading && <RouterProvider router={router} />}</GrowthBookProvider>
  );
};

export default App;
